import { PermissionEnum, RoleEnum } from "@/codes/gen/types";
import { AUTH_TOKEN } from "@/codes/util/constants";

export default {
  authToken: () => {
    return localStorage.getItem(AUTH_TOKEN);
  },
  isAuth: () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    return token !== undefined && token !== null && token.length > 0;
  },
  isWebmaster: (state: any): boolean => {
    if (!state.userInfo || !state.userInfo.roles) {
      return false;
    }

    const roleWebmaster = state.userInfo.roles.filter((r: RoleEnum) => r === RoleEnum.Webmaster);
    return roleWebmaster.length > 0;
  },
  userPermissions: (state: any): PermissionEnum[] => {
    if (!state.userInfo || !state.userInfo.roles) {
      return [];
    }

    return state.userInfo.permissions;
  },
  userRoles: (state: any): RoleEnum[] => {
    if (!state.userInfo || !state.userInfo.roles) {
      return [];
    }

    return state.userInfo.roles;
  },
  hasPermissions: (_: any, getters: any) => (neededPermissions: PermissionEnum[]): boolean => {
    for(const p of neededPermissions) {
      if (getters.hasPermission(p)) {
        return true;
      }
    }
    return false;
  },
  hasPermission: (_: any, getters: any) => (permission: PermissionEnum): boolean => {
    return getters.userPermissions.filter((p: PermissionEnum) => p === permission).length > 0;
  },
  hasRoles: (_: any, getters: any) => (roles: RoleEnum[]): boolean => {
    const intersectionRoles = roles.filter(value => getters.userRoles.includes(value));
    return intersectionRoles.length > 0;
  }
}
