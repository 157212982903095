import Swal from "sweetalert2";
import { i18n } from "@/i18n/i18n";

const { t } = i18n.global;

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    // customClass: {
    //     container: "safety-toast"
    // },
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast: any) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    }
});

const Confirm = Swal.mixin({
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonText: t("form.no").toString(),
    confirmButtonText: t("form.yes").toString(),
    // customClass: {
    //     confirmButton: "vs-component vs-button vs-button-danger vs-button-filled mx-2",
    //     cancelButton: "vs-component vs-button vs-button-primary vs-button-filled mx-2"
    // },
    // buttonsStyling: false,
    allowOutsideClick: false,
    focusCancel: true
});

const Loading = Swal.mixin({
    title: t("loading"),
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
        Swal.showLoading();
    }
});

export { Toast, Confirm, Loading }