import { createI18n } from "vue-i18n"

const i18nConfig = {
  locale: "br",
  fallbackLocale: "br",
  messages: {
    en: require("@/i18n/en.json"),
    cn: require("@/i18n/cn.json"),
    es: require("@/i18n/es.json"),
    ir: require("@/i18n/ir.json"),
    br: require("@/i18n/br.json")
  }
}

const i18n = createI18n(i18nConfig);

export { i18n };