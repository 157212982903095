type EnvModes = "production" | "development" | "local";

enum Color {
  White = "white",
  Blue = "#1e293b",
  Red = "#f32933",
  Yellow = "#e08a2c"
}

interface ConfigEnv {
  environment: EnvModes;
  appName: string;
  urlApi: string;
  navBarColor: Color;
  urlScanner: string;
  urlLogo: string;
}

const confDefault: ConfigEnv = {
  environment: "local",
  appName: "Teste Visual",
  urlApi: "",
  navBarColor: Color.White,
  urlScanner: "https://scanner.dev.testevisual.com.br/",
  urlLogo: "/img/favico.png"
};

const confTesteVisual: ConfigEnv = {
  ...confDefault,
  environment: "production",
  urlApi: "https://api.testevisual.com.br/graphql",
  urlScanner: "https://scanner.testevisual.com.br/",
  urlLogo: "/img/favico.png",
  navBarColor: Color.Red
};

const confTesteRapido: ConfigEnv = {
  environment: "production",
  appName: "Teste Visual",
  navBarColor: Color.White,
  urlApi: "https://api.testerapido.com/graphql",
  urlScanner: "https://scanner.testerapido.com/",
  urlLogo: "/img/logo-teste-visao.png"
};

const confDev: ConfigEnv = {
  ...confTesteRapido,
  environment: "development",
  navBarColor: Color.Yellow,
  urlApi: "https://api.dev.testevisual.com.br/graphql",
};

const confLocal: ConfigEnv = {
  ...confDefault,
  environment: "local",
  urlApi: "https://api.dev.testevisual.com.br/graphql",
  navBarColor: Color.Yellow
};

const getEnv = (): ConfigEnv => {
  if (window.location.host === "painel.testerapido.com") {
    return confTesteRapido;
  }

  switch (process.env.NODE_ENV as EnvModes) {
    case "development":
      return confDev;
    case "production":
      return confTesteVisual;
    case "local":
      return confLocal;
    default:
      throw new Error("Invalid EnvMode");
  }
};

export const config = getEnv();
