import { ROUTE_NAMES } from '@/codes/util/constants';

export default {
  root: {
    name: '/',
    displayName: 'menu.home',
  },
  routes: [
    // {
    //   name: "home",
    //   displayName: "menu.home",
    //   meta: {
    //     icon: "fas fa-chart-simple",
    //   },
    // },
    {
      name: ROUTE_NAMES.myAccount,
      displayName: "menu.myAccount",
      meta: {
        icon: 'fas fa-user',
      },
    },
    {
      name: ROUTE_NAMES.accounts,
      displayName: "menu.accounts",
      meta: {
        icon: 'fas fa-user-group',
      },
    },
    {
      name: ROUTE_NAMES.users,
      displayName: "menu.users",
      meta: {
        icon: 'fas fa-user-group',
      },
    },
    // {
    //   name: ROUTE_NAMES.books,
    //   displayName: "menu.books",
    //   meta: {
    //     icon: 'fas fa-book',
    //   },
    // },
    {
      name: ROUTE_NAMES.agents,
      displayName: "menu.agents",
      meta: {
        icon: 'fas fa-id-card',
      },
    },
    {
      name: ROUTE_NAMES.campaigns,
      displayName: "menu.campaigns",
      meta: {
        icon: 'fas fa-bullhorn',
      },
    },
    {
      name: ROUTE_NAMES.vouchers,
      displayName: "menu.vouchers",
      meta: {
        icon: 'fas fa-receipt',
      },
    },
    {
      name: ROUTE_NAMES.outsideBooks,
      displayName: "menu.outsideBooks",
      meta: {
        icon: 'fas fa-qrcode',
      },
    },
    {
      name: ROUTE_NAMES.exams,
      displayName: "menu.exams",
      meta: {
        icon: 'fas fa-arrows-to-eye',
      },
    },
    {
      name: ROUTE_NAMES.scan,
      displayName: "menu.scan",
      meta: {
        icon: 'fas fa-barcode',
      },
    },
  ],
}
