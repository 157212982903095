const DEFAULT_ITEMS_PER_PAGE = 25;

const AUTH_TOKEN = 'auth_token';

const VUEX_ACTIONS = {
  setUserInfo: "setUserInfo",
  setAuthToken: "setAuthToken",
  updateAuthToken: "updateAuthToken",
  logout: "logout",
  showSelectAccount: "showSelectAccount",
  hideSelectAccount: "hideSelectAccount",
}

const ROUTE_NAMES = {
  home: "home",
  myAccount: "myAccount",
  accounts: "accounts",
  newAccount: "newAccount",
  editAccount: "editAccount",
  orders: "orders",
  users: "users",
  newUser: "newUser",
  editUser: "editUser",
  books: "books",
  agents: "agents",
  campaigns: "campaigns",
  vouchers: "vouchers",
  newVoucher: "newVoucher",
  editVoucher: "editVoucher",
  outsideBooks: "outsideBooks",
  newOutsideBook: "newOutsideBook",
  editOutsideBook: "editOutsideBook",
  exams: "exams",
  scan: "scan",
}

export { AUTH_TOKEN, VUEX_ACTIONS, ROUTE_NAMES, DEFAULT_ITEMS_PER_PAGE }
