import { ROUTE_NAMES } from '@/codes/util/constants';
import { PermissionEnum } from '../gen/types';

const PermissionsByRoute = {
  [ROUTE_NAMES.accounts]: [PermissionEnum.AccountRead, PermissionEnum.AccountWrite],
  [ROUTE_NAMES.users]: [PermissionEnum.UserWrite, PermissionEnum.UserRead],
  [ROUTE_NAMES.books]: [PermissionEnum.BookRead, PermissionEnum.BookWrite],
  [ROUTE_NAMES.agents]: [PermissionEnum.AgentRead, PermissionEnum.AgentWrite],
  [ROUTE_NAMES.campaigns]: [PermissionEnum.CampaignRead, PermissionEnum.CampaignWrite],
  [ROUTE_NAMES.vouchers]: [PermissionEnum.VoucherRead, PermissionEnum.VoucherWrite],
  [ROUTE_NAMES.outsideBooks]: [PermissionEnum.OutsideBookRead, PermissionEnum.OutsideBookWrite],
  [ROUTE_NAMES.myAccount]: [PermissionEnum.MyAccountUpdate]
}

const WritePermissionsByRoute = {
  [ROUTE_NAMES.accounts]: [PermissionEnum.AccountWrite],
  [ROUTE_NAMES.users]: [PermissionEnum.UserWrite],
  [ROUTE_NAMES.books]: [PermissionEnum.BookWrite],
  [ROUTE_NAMES.agents]: [PermissionEnum.AgentWrite],
  [ROUTE_NAMES.campaigns]: [PermissionEnum.CampaignWrite],
  [ROUTE_NAMES.vouchers]: [PermissionEnum.VoucherWrite],
  [ROUTE_NAMES.outsideBooks]: [PermissionEnum.OutsideBookWrite],
}

export { PermissionsByRoute, WritePermissionsByRoute };
