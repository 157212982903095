import gql from "graphql-tag";
import {
  Account,
  AccountCreateInput,
  AccountEdgesResponse,
  AccountResponse,
  AccountSearchInput,
  AccountUpdateInput,
  MyAccountUpdateInput,
  SortOrderEnum, User
} from "../gen/types";
import { ResponseGql } from "../types/MDOTypes";
import { BaseService } from "./BaseService";

export class AccountService extends BaseService {

  constructor() {
    super();
  }

  get(id: string): ResponseGql<Account> {
    const query = gql`query account($id: ID!) {
      account(id: $id) {
        id
        name
        cnpj
        whatsapp
        email
        phone
        disabledBySaas
        flags {
          activateVouchers
          activate12Med
          activateCheckSaasStatus
        }
        address {
          street
          number
          postcode
          district
          complement
          city
          state
          country
        }
        deletedAt{
          transform(format: "DD/MM/yyyy HH:mm")
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id }}).then((result) => {
      return result.data.account;
    });
  }

  getAll(searchInput: AccountSearchInput): ResponseGql<AccountEdgesResponse> {
   const query = gql`query accounts($accountsSearchInput: AccountSearchInput) {
      accounts(params: $accountsSearchInput) {
        edges {
          id
          name
          cnpj
          email
          phone
          whatsapp
          disabledBySaas
          deletedAt{
            transform(format: "DD/MM/yyyy HH:mm")
          }
        }
        pagination {
          total
          page
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: {accountsSearchInput:searchInput} }).then((response) => response.data.accounts);
  }

  getOptions(): ResponseGql<AccountEdgesResponse> {
    const searchInput = { sort: { name: SortOrderEnum.Asc } }
   const query = gql`query accounts($accountsSearchInput: AccountSearchInput) {
      accounts(params: $accountsSearchInput) {
        edges {
          id
          name
          cnpj
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: {accountsSearchInput:searchInput} }).then((response) => response.data.accounts);
  }

  getMyAccount(): ResponseGql<User> {
    const query = gql`query myAccount {
      myUser {
        deletedAt{
          transform(format: "DD/MM/yyyy HH:mm")
        }
        sessionAccount {
          id
          name
          cnpj
          whatsapp
          email
          phone
          address {
            street
            number
            postcode
            district
            complement
            city
            state
            country
          }
          configs {
            promotion {
              enable
              type
              value
            }
          }
          deletedAt{
            transform(format: "DD/MM/yyyy HH:mm")
          }
        }
      }
    }`;

    return this.apolloClient.query({ query: query })
    .then(result => {
      return result.data.myUser
    });
  }

  updateMyAccount(myAccount: MyAccountUpdateInput): ResponseGql<AccountResponse> {
    const update = gql`mutation updateMyAccount($myAccount: MyAccountUpdateInput!) {
      updateMyAccount(input: $myAccount) {
        data {
          id
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: update, variables: { myAccount: myAccount }})
    .then(result => result.data.updateMyAccount);
  }

  save(account: AccountCreateInput): ResponseGql<AccountResponse> {
    const query = gql`mutation createAccount($account: AccountCreateInput!) {
      createAccount(input: $account) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: query, variables: { account: account }}).then((result) => result.data.createAccount);
  }

  update(id: string, account: AccountUpdateInput): ResponseGql<AccountResponse> {
    const query = gql`mutation updateAccount($id: ID!, $account:AccountUpdateInput!) {
      updateAccount(id: $id, input: $account) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: query, variables: { id: id, account: account }}).then((result) => result.data.updateAccount);
  }

  remove(id: string): ResponseGql<AccountResponse> {
    const query = gql`
    mutation removeAccount($id: ID!) {
      removeAccount(id: $id) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: query, variables: { id: id }}).then((result) => {
      return result.data.removeAccount
    });
  }
  restore(id: string): ResponseGql<AccountResponse> {
    const query = gql`
    mutation restoreAccount($id: ID!) {
      restoreAccount(id: $id) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: query, variables: { id: id }}).then((result) => {
      return result.data.restoreAccount
    });
  }
}
