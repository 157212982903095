import { User } from "@/codes/gen/types";
import { AUTH_TOKEN, VUEX_ACTIONS } from "@/codes/util/constants";

export default {
  updateSidebarCollapsedState(state: any, isSidebarMinimized: boolean) {
    state.isSidebarMinimized = isSidebarMinimized;
  },
  changeUserName(state: any, newUserName: string) {
    state.userName = newUserName;
  },
  [VUEX_ACTIONS.setAuthToken]: function(state: any, token: string) {
    if (!token || token === 'undefined') {
      return;
    }
    localStorage.setItem(AUTH_TOKEN, token);
  },
  [VUEX_ACTIONS.logout]: function() {
    localStorage.removeItem(AUTH_TOKEN);
  },
  [VUEX_ACTIONS.setUserInfo]: function(state: any, userInfo: User) {
    state.userInfo = userInfo;
  },
}