<template>
  <va-sidebar
    :width="width"
    :minimized="minimized" 
    :minimized-width="minimizedWidth"
  >
    <menu-minimized
      v-if="minimized"
      :items="itemsWithPermission"
    />
    <menu-accordion
      v-else
      :items="itemsWithPermission"
    />
    <logged-user-data />
  </va-sidebar>    
</template>

<script>
import { useGlobalConfig } from 'vuestic-ui';
import MenuAccordion from './menu/MenuAccordion.vue';
import MenuMinimized from './menu/MenuMinimized.vue';
import NavigationRoutes from './NavigationRoutes.ts';
import LoggedUserData from '@/components/sidebar/LoggedUserData.vue';
import { useStore } from 'vuex';
import { PermissionsByRoute } from '@/codes/types/PermissionsByRoute';

export default {
  name: "AppSidebar",
  components: {
    MenuAccordion,
    MenuMinimized,
    LoggedUserData
  },
  props: {
    width: { type: String, default: '16rem' },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    itemsWithPermission() {
      const store = useStore();
      const withPermission = [];

      for (const item of NavigationRoutes.routes) {
        if (item.children) {
          const wp = this.childrenHasPermission(item.children, store);
          if (wp && wp.length > 0) {
            item.children = wp;
            withPermission.push(item);
          }
        } else {
          const permissions = PermissionsByRoute[item.name];
          if (permissions) {
            if (store.getters.hasPermissions(permissions)) {
              withPermission.push(item);
            }
          } else {
            withPermission.push(item);
          }
        }
      }

      return withPermission;
    },
    computedClass() {
      return {
        "app-sidebar--minimized": this.minimized
      };
    },
    colors() {
      return useGlobalConfig().getGlobalConfig().colors
    },
  },
  methods: {
    childrenHasPermission: function(children, store) {
      const abc = [];
      for (const child of children) {
        if (child.children) {
          const wp = this.childrenHasPermission(child.children, store);
          if (wp.length > 0) {
            child.children = wp;
            abc.push(child);
          }
        } else {
          const permissions = PermissionsByRoute[child.name];
          if (permissions) {
            if (store.getters.hasPermissions(permissions)) {
              abc.push(child);
            }
          } else {
            abc.push(child);
          }
        }
      }
      return abc;
    }
  },
};
</script>

<style lang="scss">
.va-sidebar {
  .va-collapse__body {
    margin-top: 0 !important;
  }

  &__menu {
    padding: 2rem 0;
    &__inner {
      padding-bottom: 8rem;
    }
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
  flex-shrink: 0;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>
