<template>
  <div
    style="display: flex; justify-content: center; align-items: center"
    :style="{'flex-direction': direction}"
  >
    <img
      :src="logo"
      alt="Logo"
      style="width: fit-content; display: block;"
      :height="height"
    >
  </div>
</template>
<script>
import { useColors } from "vuestic-ui";
import { config } from "@/config";

export default {
  name: "VaIconVuestic",

  props: {
    height: { type: [Number, String], default: 54 },
    color: { type: [String], default: "primary" },
    direction: { type: [String], default: "row" },
  },
  setup(){
    const logo = config.urlLogo;

    return{logo}
  },

  computed: {
    colorsComputed() {
      const { getColor, shiftHSLAColor } = useColors();
      const color = getColor(this.color, "primary");

      return { start: color, end: shiftHSLAColor(color, { l: -20 }) };
    },
  }
};
</script>
