<template>
  <div class="auth-layout row align-content--center">
    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <vuestic-logo
          style="margin: 20px"
          direction="column"
          height="60"
        />
      </div>

      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-alert
            class="mb-4 text-center"
            :description="$t('auth.title')"
          />
          <va-card-content>
            <div class="pa-3">
              <router-view />
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import VuesticLogo from "@/components/vuestic-logo";

export default {
  name: "AuthLayout",
  components: { VuesticLogo },
  data() {
    return {
      selectedTabIndex: 0
    };
  },
  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName });
      },
      get() {
        return this.$route.name;
      }
    }
  }
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }
}
</style>
