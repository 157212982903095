export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date JS */
  Date: any;
};

export type Account = {
  __typename?: 'Account';
  address?: Maybe<Address>;
  cnpj: Scalars['String'];
  configs?: Maybe<AccountConfigs>;
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  email?: Maybe<Scalars['String']>;
  flags: AccountFlags;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: CustomDate;
  whatsapp?: Maybe<Scalars['String']>;
  disabledBySaas?: Maybe<Scalars['String']>;
};

export type AccountConditionAndInput = {
  cnpj?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type AccountConfigs = {
  __typename?: 'AccountConfigs';
  promotion?: Maybe<PromotionConfig>;
};

export type AccountConfigsInput = {
  promotion?: InputMaybe<PromotionConfigInput>;
};

export type AccountCreateInput = {
  address?: InputMaybe<AddressInput>;
  cnpj: Scalars['String'];
  configs?: InputMaybe<AccountConfigsInput>;
  email?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<AccountFlagsInput>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type AccountEdgesResponse = {
  __typename?: 'AccountEdgesResponse';
  edges: Array<Account>;
  pagination?: Maybe<Pagination>;
};

export type AccountFlags = {
  __typename?: 'AccountFlags';
  activate12Med: Scalars['Boolean'];
  /** Valida situação SaaS */
  activateCheckSaasStatus: Scalars['Boolean'];
  activateVouchers: Scalars['Boolean'];
};

export type AccountFlagsInput = {
  activate12Med?: InputMaybe<Scalars['Boolean']>;
  activateCheckSaasStatus?: InputMaybe<Scalars['Boolean']>;
  activateVouchers?: InputMaybe<Scalars['Boolean']>;
};

export type AccountResponse = {
  __typename?: 'AccountResponse';
  data: Account;
  message: Scalars['String'];
};

export type AccountSearchInput = {
  and?: InputMaybe<AccountConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<FilterScopeEnum>;
  showHidden?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<SortAccountInput>;
};

export type AccountUpdateInput = {
  address?: InputMaybe<AddressInput>;
  cnpj?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<AccountConfigsInput>;
  email?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<AccountFlagsInput>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type Agent = {
  __typename?: 'Agent';
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  enable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: CustomDate;
};

export type Agent12Med = {
  __typename?: 'Agent12Med';
  crm: Scalars['String'];
  name: Scalars['String'];
};

export type AgentConditionAndInput = {
  enable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AgentCreateInput = {
  enable: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AgentEdgesResponse = {
  __typename?: 'AgentEdgesResponse';
  edges: Array<Agent>;
  pagination?: Maybe<Pagination>;
};

export type AgentResponse = {
  __typename?: 'AgentResponse';
  data: Agent;
  message: Scalars['String'];
};

export type AgentSearchInput = {
  and?: InputMaybe<AgentConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortAgentInput>;
};

export type AgentUpdateInput = {
  enable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AppPromotion = {
  __typename?: 'AppPromotion';
  type: PromotionTypeEnum;
  value: Scalars['String'];
};

export type BaseBook = {
  account?: Maybe<Account>;
  agent?: Maybe<Agent>;
  campaign?: Maybe<Campaign>;
};

export type Book = BaseBook & {
  __typename?: 'Book';
  account?: Maybe<Account>;
  agent?: Maybe<Agent>;
  campaign?: Maybe<Campaign>;
  /** Data em que o talão foi ativado na loja */
  checkInAt?: Maybe<CustomDate>;
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  /** Data em que o talão foi enviado para a loja */
  dispatchedAt?: Maybe<CustomDate>;
  id: Scalars['ID'];
  logs: Array<BookLog>;
  /** UUID para agrupar uma coleção de talões que foram gerados juntos */
  lote: Scalars['String'];
  /** Quantidade usuarios que escaneou o book, mais nao finalizaram o exame */
  qtAbandoned: Scalars['Int'];
  /** Quantidade de exames efetuados */
  qtExamDone: Scalars['Int'];
  /** Quantidade de folhas que o talões ja foi escaneado almenos uma vez */
  qtScannedSheets: Scalars['Int'];
  sequential: Scalars['Int'];
  sheets: Array<Sheet>;
  status: BookStatusEnum;
  updatedAt: CustomDate;
  /** Data de encerramento quando todos os "sheets" foram escaneados almenos uma vez */
  usedAt?: Maybe<CustomDate>;
};

export type BookConditionAndInput = {
  account?: InputMaybe<Scalars['ID']>;
  agent?: InputMaybe<Scalars['ID']>;
  campaign?: InputMaybe<Scalars['ID']>;
  lote?: InputMaybe<Scalars['String']>;
  sequential?: InputMaybe<ConditionIntInput>;
  status?: InputMaybe<BookStatusEnum>;
};

export type BookEdgesResponse = {
  __typename?: 'BookEdgesResponse';
  edges: Array<Book>;
  linkDownloadCSV: Scalars['String'];
  /** @deprecated Use linkDownloadCSV instead */
  linkDownloadCsv: Scalars['String'];
  linkDownloadPDF: Scalars['String'];
  linkDownloadPDFLayout: Scalars['String'];
  pagination?: Maybe<Pagination>;
};

export enum BookFromEnum {
  QrCode = 'QrCode',
  SharedLink = 'SharedLink'
}

/**
 * **Exemplo de LOGS:**
 *
 * log 1 => user xpto, franqueado, criou book
 * log 2 => user xpto, franqueado, despachou para loja xxx
 * log 3 => user xxx, loja, alterou para campanha demo1 e agente age1
 * log 3 => user xxx, loja, alterou para campanha demo2 e agente age1
 * log 4 => sistema, marcou book como usado
 */
export type BookLog = {
  __typename?: 'BookLog';
  account?: Maybe<Scalars['ID']>;
  accountName?: Maybe<Scalars['String']>;
  action: BookLogActionEnum;
  agent?: Maybe<Scalars['ID']>;
  agentName?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['ID']>;
  campaignName?: Maybe<Scalars['String']>;
  createdAt: CustomDate;
  from: BookLogFromEnum;
  id: Scalars['ID'];
  user?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};

export enum BookLogActionEnum {
  AttachCampaign = 'AttachCampaign',
  CancelDispatched = 'CancelDispatched',
  CheckIn = 'CheckIn',
  Created = 'Created',
  Dispatched = 'Dispatched',
  Exported = 'Exported',
  UsedAll = 'UsedAll'
}

export enum BookLogFromEnum {
  Franchise = 'Franchise',
  Store = 'Store',
  System = 'System',
  Webmaster = 'Webmaster'
}

export type BookSearchInput = {
  and?: InputMaybe<BookConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortBookInput>;
};

export enum BookStatusEnum {
  Available = 'Available',
  Dispatched = 'Dispatched',
  InCampaign = 'InCampaign',
  InTheStore = 'InTheStore',
  Pending = 'Pending',
  Used = 'Used'
}

export enum BookTypeEnum {
  Inside = 'Inside',
  Outside = 'Outside'
}

export type Campaign = {
  __typename?: 'Campaign';
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  description?: Maybe<Scalars['String']>;
  enable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: CustomDate;
  whatsapp?: Maybe<Scalars['String']>;
};

export type CampaignConditionAndInput = {
  enable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type CampaignCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  enable: Scalars['Boolean'];
  name: Scalars['String'];
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type CampaignEdgesResponse = {
  __typename?: 'CampaignEdgesResponse';
  edges: Array<Campaign>;
  pagination?: Maybe<Pagination>;
};

export type CampaignResponse = {
  __typename?: 'CampaignResponse';
  data: Campaign;
  message: Scalars['String'];
};

export type CampaignSearchInput = {
  and?: InputMaybe<CampaignConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortCampaignInput>;
};

export type CampaignUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  enable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type ClientExam = {
  __typename?: 'ClientExam';
  accepted12med?: Maybe<Scalars['Boolean']>;
  acceptedReceiveMessage?: Maybe<Scalars['Boolean']>;
  age: Scalars['Int'];
  camera?: Maybe<Scalars['Boolean']>;
  glasses: Scalars['Boolean'];
  name: Scalars['String'];
  whatsapp?: Maybe<Scalars['String']>;
};

export type ClientExamInput = {
  accepted12med?: InputMaybe<Scalars['Boolean']>;
  acceptedReceiveMessage?: InputMaybe<Scalars['Boolean']>;
  age: Scalars['Int'];
  camera?: InputMaybe<Scalars['Boolean']>;
  glasses: Scalars['Boolean'];
  name: Scalars['String'];
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type CodeVoucherResponse = {
  __typename?: 'CodeVoucherResponse';
  data: VoucherSimple;
  message: Scalars['String'];
};

export type ConditionDateInput = {
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
};

export type ConditionFloatInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type ConditionIntInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type ConversionSerieChart = {
  __typename?: 'ConversionSerieChart';
  conversion: Scalars['Float'];
  percent: Scalars['Float'];
  status: Scalars['String'];
  value: Scalars['Float'];
};

export enum CountryEnum {
  Br = 'BR',
  Es = 'ES',
  Pt = 'PT'
}

export type CreateBookResponse = {
  __typename?: 'CreateBookResponse';
  lote: Scalars['String'];
  message: Scalars['String'];
  quantity: Scalars['Int'];
  sequenceBegin: Scalars['Int'];
  sequenceEnd: Scalars['Int'];
};

export type CustomDate = {
  __typename?: 'CustomDate';
  /** Formats a string to the ISO8601 standard. */
  iso?: Maybe<Scalars['String']>;
  /** String matches the RFC 2822 Date time format */
  string?: Maybe<Scalars['String']>;
  /** Integer value representing the number of milliseconds since the Unix Epoch (Jan 1 1970 12AM UTC). */
  timestamp?: Maybe<Scalars['Float']>;
  /** This function returns the real offset from UTC, not the reverse offset */
  timezone?: Maybe<Scalars['String']>;
  /**
   * If you know the format of an input string, you can use that to parse a date.
   * Read more: [momentjs format date](https://momentjs.com/docs/#/parsing/string-format)
   */
  transform?: Maybe<Scalars['String']>;
  /** Unix timestamp (seconds since the Unix Epoch) */
  unix?: Maybe<Scalars['Float']>;
  /** UTC time instead of local time */
  utc?: Maybe<Scalars['String']>;
};


export type CustomDateTimezoneArgs = {
  offset: Scalars['String'];
};


export type CustomDateTransformArgs = {
  format: Scalars['String'];
};

export type CustomNumber = {
  __typename?: 'CustomNumber';
  fixed?: Maybe<Scalars['String']>;
  float?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  int?: Maybe<Scalars['Int']>;
  string?: Maybe<Scalars['String']>;
};


export type CustomNumberFixedArgs = {
  digits: Scalars['Int'];
};


export type CustomNumberFormatArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  digits?: InputMaybe<Scalars['Int']>;
};

export type DashboardResponse = {
  __typename?: 'DashboardResponse';
  exams: ExamDashboard;
  examsVsScans: ExamsVsScansDashboard;
  results: ExamResultsDashboard;
  vouchers: VoucherDashboard;
};

export type Data12Med = {
  __typename?: 'Data12Med';
  agent?: Maybe<Agent12Med>;
  date: CustomDate;
  status: Data12MedStatusEnum;
  statusNumber: Scalars['Int'];
  statusText: Scalars['String'];
};

export enum Data12MedStatusEnum {
  CancelledByClient = 'CancelledByClient',
  CancelledByDoctor = 'CancelledByDoctor',
  CancelledBySecretary = 'CancelledBySecretary',
  Created = 'Created',
  PaymentApproved = 'PaymentApproved',
  PaymentDeclined = 'PaymentDeclined',
  Performed = 'Performed',
  Rescheduled = 'Rescheduled',
  Scheduled = 'Scheduled',
  Unknow = 'Unknow',
  WaitingSchedule = 'WaitingSchedule'
}

export type DataAccountTable = {
  __typename?: 'DataAccountTable';
  account?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  quantityConversions: Scalars['Int'];
  quantityResults: Scalars['Int'];
  quantityScans: Scalars['Int'];
};

export type Exam = BaseBook & {
  __typename?: 'Exam';
  account: Account;
  agent: Agent;
  bookIn?: Maybe<Scalars['ID']>;
  bookOut?: Maybe<Scalars['ID']>;
  campaign: Campaign;
  client: ClientExam;
  /** Se o cliente abriu o whatsapp */
  convertedAt?: Maybe<Scalars['Boolean']>;
  createdAt: CustomDate;
  from: BookFromEnum;
  /** Geolocalização do cliente no momento q ele escaneou o código */
  geo?: Maybe<Array<Scalars['Float']>>;
  id: Scalars['ID'];
  info12Med?: Maybe<Info12Med>;
  resultData?: Maybe<ResultExam>;
  resultImage?: Maybe<Scalars['String']>;
  resultLink?: Maybe<Scalars['String']>;
  /** Obs: sera null quando for do tipo=outside */
  sheet?: Maybe<Scalars['ID']>;
  status: ExamStatusEnum;
  type: BookTypeEnum;
  updatedAt: CustomDate;
  /** Caso o cliente recebeu algum voucher, sera retornado aqui os detalhes do voucher */
  voucher?: Maybe<ExamVoucher>;
};

export type ExamConditionAndInput = {
  account?: InputMaybe<Scalars['ID']>;
  agent?: InputMaybe<Scalars['ID']>;
  bookIn?: InputMaybe<Scalars['ID']>;
  bookOut?: InputMaybe<Scalars['ID']>;
  campaign?: InputMaybe<Scalars['ID']>;
  convertedAt?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<ConditionDateInput>;
  from?: InputMaybe<BookFromEnum>;
  resultData_overall?: InputMaybe<Array<ResultTestEnum>>;
  sheet?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Array<ExamStatusEnum>>;
  status12med?: InputMaybe<Array<Data12MedStatusEnum>>;
  type?: InputMaybe<BookTypeEnum>;
  voucher?: InputMaybe<Scalars['ID']>;
  voucherCode?: InputMaybe<Scalars['ID']>;
};

export type ExamDashboard = {
  __typename?: 'ExamDashboard';
  conversionFunnel: Array<SerieChart>;
  examsByFrom: Array<SerieChart>;
  examsByStatus: Array<SerieChart>;
  rankingAccounts: Array<DataAccountTable>;
  rankingAgents: Array<DataAccountTable>;
  rankingCampaigns: Array<DataAccountTable>;
  scansByType: Array<SerieChart>;
  userWithCamera: Array<SerieChart>;
};

export type ExamEdgesResponse = {
  __typename?: 'ExamEdgesResponse';
  edges: Array<Exam>;
  linkDownloadCSV: Scalars['String'];
  linkDownloadXLS: Scalars['String'];
  pagination?: Maybe<Pagination>;
};

export type ExamResultsDashboard = {
  __typename?: 'ExamResultsDashboard';
  conversionByResult: Array<ConversionSerieChart>;
  resultByStatus: Array<SerieChart>;
  resultsPerAgeGroup: Array<GroupSerieChart>;
  wearGlasses: Array<SerieChart>;
};

export type ExamSearchInput = {
  and?: InputMaybe<ExamConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  onlyLeads?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortExamInput>;
};

export enum ExamStatusEnum {
  Abandoned = 'Abandoned',
  Converted = 'Converted',
  Finished = 'Finished',
  Initiated = 'Initiated'
}

export type ExamVoucher = {
  __typename?: 'ExamVoucher';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  discountLabel: Scalars['String'];
  discountType: VoucherDiscountTypeEnum;
  discountValue: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  registeredAt: CustomDate;
  usedAt?: Maybe<CustomDate>;
};

export type ExamsVsScansDashboard = {
  __typename?: 'ExamsVsScansDashboard';
  exams: Array<IntervalChart>;
  scans: Array<IntervalChart>;
};

export enum FilterScopeEnum {
  OnlyActive = 'OnlyActive',
  OnlyTrashed = 'OnlyTrashed',
  WithTrashed = 'WithTrashed'
}

export type GroupItemSerieChart = {
  __typename?: 'GroupItemSerieChart';
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type GroupSerieChart = {
  __typename?: 'GroupSerieChart';
  color: Scalars['String'];
  label: Scalars['String'];
  values: Array<GroupItemSerieChart>;
};

export type Info12Med = {
  __typename?: 'Info12Med';
  data: Array<Data12Med>;
  status?: Maybe<Data12MedStatusEnum>;
  statusNumber: Scalars['Int'];
  statusText: Scalars['String'];
};

export type InfoApi = {
  __typename?: 'InfoAPI';
  commit: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  env: Scalars['String'];
  envName: Scalars['String'];
  image: Scalars['String'];
  packageName: Scalars['String'];
  shortCommit: Scalars['String'];
  version: Scalars['String'];
};

export type InitExamResponse = {
  __typename?: 'InitExamResponse';
  examId: Scalars['ID'];
  message: Scalars['String'];
};

export type IntervalChart = {
  __typename?: 'IntervalChart';
  dateLabel: Scalars['String'];
  x: Scalars['String'];
  y: Scalars['Float'];
};

export type LoginInput = {
  /** Hash device */
  password: Scalars['String'];
  /** E-mail user */
  username: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  /**
   * Mesmo tokenAPI concatenando Bearer
   * Forma pratica de copiar token para colar no header do playground
   */
  Authorization: Scalars['String'];
  /** JWT access API */
  accessToken: Scalars['String'];
  /** Tempo para expirar o token */
  expiresIn: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * ## Loja associar talões a distribuidores Street e campanha
   *
   * Arg `numbers`: array deve conter entre 1~50 numeros sequenciais de talões.
   *
   * Obs:
   * - Ação só pode ser feito por usuários com permissão `BookAttachCampaign`.
   * - Requer xAccount para identificar a loja.
   */
  attachCampaignToBooks: TransitionBooksResponse;
  /**
   * ## Força um talão retornar para franqueadora.
   * Use com atenção, pois ele ira desvincular o talão da loja, campanha, agent ...
   *
   * API vai retornar a quantidade de talões que tiverem sucesso e a quantidade que ocorram falhas,
   * Além de um array detalhando o motivo de falha de cada talão.
   *
   * Arg `numbers`: array deve conter entre 1~50 numeros sequenciais de talões.
   *
   * Obs: Ação só pode ser feito por usuários com permissão `BookDispatch`.
   */
  cancelDispatchedBooks: TransitionBooksResponse;
  /**
   * ## Quando a loja recebe o talão
   * Será necessario um xAccount para identificar a loja.
   *
   * Arg `numbers`: array deve conter entre 1~50 numeros sequenciais de talões.
   *
   * Obs:
   * - Ação só pode ser feito por usuários com permissão `BookCheckInAccount`.
   * - Requer xAccount para identificar a loja.
   */
  checkInAccountBooks: TransitionBooksResponse;
  codeConfirmVoucher: CodeVoucherResponse;
  codeValidatorExam: ValidatorExamResponse;
  codeValidatorVoucher: CodeVoucherResponse;
  createAccount: AccountResponse;
  createAgent: AgentResponse;
  /**
   * ## Emite talões
   * O talão tem 10 folhas, em cada folha 1 QR com identificador.
   *
   * Args `quantity`: precisa apenas informar a quantidade de talões a ser gerado (valores aceitos: 1~50).
   *
   * Obs: Ação só pode ser feito por usuários com permissão `BookWrite`.
   */
  createBook: CreateBookResponse;
  createCampaign: CampaignResponse;
  createOutsideBook: OutsideBookResponse;
  createUser: UserResponse;
  createVoucher: VoucherResponse;
  /**
   * ## Ação onde a franqueadora envia os talões para a loja.
   * Com os talões impressos a franqueado irá associar um talão pelo identificar a uma loja e então poderá despachar.
   *
   * API vai retornar a quantidade de talões que tiverem sucesso e a quantidade que ocorram falhas,
   * Além de um array detalhando o motivo de falha de cada talão.
   *
   * Arg `numbers`: array deve conter entre 1~50 numeros sequenciais de talões.
   *
   * Obs: Ação só pode ser feito por usuários com permissão `BookDispatch`.
   */
  dispatchBooks: TransitionBooksResponse;
  initExam: InitExamResponse;
  login: LoginResponse;
  loginWithAccount: LoginResponse;
  removeAccount: AccountResponse;
  removeAgent: AgentResponse;
  removeCampaign: CampaignResponse;
  removeOutsideBook: OutsideBookResponse;
  removeUser: UserResponse;
  removeVoucher: VoucherResponse;
  restoreAccount: AccountResponse;
  restoreAgent: AgentResponse;
  restoreCampaign: CampaignResponse;
  restoreOutsideBook: OutsideBookResponse;
  restoreUser: UserResponse;
  restoreVoucher: VoucherResponse;
  setConvertedExam: Scalars['Boolean'];
  setResultExam: SetResultExamResponse;
  updateAccount: AccountResponse;
  updateAgent: AgentResponse;
  updateCampaign: CampaignResponse;
  updateMyAccount: AccountResponse;
  /** @deprecated Use updateMyAccount instead */
  updateMyAccountConfigs: AccountResponse;
  updateMyUser: UserResponse;
  updateOutsideBook: OutsideBookResponse;
  updateUser: UserResponse;
  updateVoucher: VoucherResponse;
};


export type MutationAttachCampaignToBooksArgs = {
  agent: Scalars['ID'];
  campaign: Scalars['ID'];
  numbers: Array<Scalars['Int']>;
};


export type MutationCancelDispatchedBooksArgs = {
  numbers: Array<Scalars['Int']>;
};


export type MutationCheckInAccountBooksArgs = {
  numbers: Array<Scalars['Int']>;
};


export type MutationCodeConfirmVoucherArgs = {
  code: Scalars['String'];
};


export type MutationCodeValidatorExamArgs = {
  code: Scalars['String'];
};


export type MutationCodeValidatorVoucherArgs = {
  code: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  input: AccountCreateInput;
};


export type MutationCreateAgentArgs = {
  input: AgentCreateInput;
};


export type MutationCreateBookArgs = {
  quantity: Scalars['Int'];
};


export type MutationCreateCampaignArgs = {
  input: CampaignCreateInput;
};


export type MutationCreateOutsideBookArgs = {
  input: OutsideBookCreateInput;
};


export type MutationCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationCreateVoucherArgs = {
  input: VoucherCreateInput;
};


export type MutationDispatchBooksArgs = {
  account: Scalars['ID'];
  numbers: Array<Scalars['Int']>;
};


export type MutationInitExamArgs = {
  client: ClientExamInput;
  code: Scalars['String'];
  geo?: InputMaybe<Array<Scalars['Float']>>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginWithAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationRemoveAccountArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveAgentArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCampaignArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveOutsideBookArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveVoucherArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreAccountArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreAgentArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreCampaignArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreOutsideBookArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreUserArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreVoucherArgs = {
  id: Scalars['ID'];
};


export type MutationSetConvertedExamArgs = {
  examId: Scalars['ID'];
};


export type MutationSetResultExamArgs = {
  client?: InputMaybe<ClientExamInput>;
  examId: Scalars['ID'];
  geo?: InputMaybe<Array<Scalars['Float']>>;
  result: ResultExamInput;
};


export type MutationUpdateAccountArgs = {
  id: Scalars['ID'];
  input: AccountUpdateInput;
};


export type MutationUpdateAgentArgs = {
  id: Scalars['ID'];
  input: AgentUpdateInput;
};


export type MutationUpdateCampaignArgs = {
  id: Scalars['ID'];
  input: CampaignUpdateInput;
};


export type MutationUpdateMyAccountArgs = {
  input: MyAccountUpdateInput;
};


export type MutationUpdateMyAccountConfigsArgs = {
  configs: AccountConfigsInput;
};


export type MutationUpdateMyUserArgs = {
  input: MyUserUpdateInput;
};


export type MutationUpdateOutsideBookArgs = {
  id: Scalars['ID'];
  input: OutsideBookUpdateInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UserUpdateInput;
};


export type MutationUpdateVoucherArgs = {
  id: Scalars['ID'];
  input: VoucherUpdateInput;
};

export type MyAccountUpdateInput = {
  address?: InputMaybe<AddressInput>;
  cnpj?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<AccountConfigsInput>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type MyUserUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type OutsideBook = BaseBook & {
  __typename?: 'OutsideBook';
  account?: Maybe<Account>;
  agent?: Maybe<Agent>;
  campaign?: Maybe<Campaign>;
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  description?: Maybe<Scalars['String']>;
  enable: Scalars['Boolean'];
  id: Scalars['ID'];
  linkShare: Scalars['String'];
  name: Scalars['String'];
  qrCode: QrCodeLink;
  /** Quantidade usuarios que escaneou o book, mais nao finalizaram o exame */
  qtAbandoned: Scalars['Int'];
  /** Quantidade de exames efetuados */
  qtExamDone: Scalars['Int'];
  /** Quantidade de exames efetuados via QrCode */
  qtExamFromQrCode: Scalars['Int'];
  /** Quantidade de exames efetuados via link compartilhado */
  qtExamFromShare: Scalars['Int'];
  /** Quantidade de folhas que o talão ja foi escaneado almenos uma vez */
  qtScanned: Scalars['Int'];
  updatedAt: CustomDate;
};

export type OutsideBookConditionAndInput = {
  agent?: InputMaybe<Scalars['ID']>;
  campaign?: InputMaybe<Scalars['ID']>;
  enable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type OutsideBookCreateInput = {
  agent: Scalars['ID'];
  campaign: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  enable: Scalars['Boolean'];
  name: Scalars['String'];
};

export type OutsideBookEdgesResponse = {
  __typename?: 'OutsideBookEdgesResponse';
  edges: Array<OutsideBook>;
  pagination?: Maybe<Pagination>;
};

export type OutsideBookResponse = {
  __typename?: 'OutsideBookResponse';
  data: OutsideBook;
  message: Scalars['String'];
};

export type OutsideBookSearchInput = {
  and?: InputMaybe<OutsideBookConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortOutsideBookInput>;
};

export type OutsideBookUpdateInput = {
  agent?: InputMaybe<Scalars['ID']>;
  campaign?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  enable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  lastPage: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PermissionEnum {
  AccountRead = 'AccountRead',
  AccountWrite = 'AccountWrite',
  AclRead = 'AclRead',
  AgentRead = 'AgentRead',
  AgentWrite = 'AgentWrite',
  BookAttachCampaign = 'BookAttachCampaign',
  BookCheckInAccount = 'BookCheckInAccount',
  BookDispatch = 'BookDispatch',
  BookRead = 'BookRead',
  BookWrite = 'BookWrite',
  CampaignRead = 'CampaignRead',
  CampaignWrite = 'CampaignWrite',
  DashboardAccount = 'DashboardAccount',
  DashboardAdmin = 'DashboardAdmin',
  ExamRead = 'ExamRead',
  LoggingWithAccount = 'LoggingWithAccount',
  MyAccountUpdate = 'MyAccountUpdate',
  OutsideBookRead = 'OutsideBookRead',
  OutsideBookWrite = 'OutsideBookWrite',
  UserRead = 'UserRead',
  UserWrite = 'UserWrite',
  VoucherConfirm = 'VoucherConfirm',
  VoucherRead = 'VoucherRead',
  VoucherValidator = 'VoucherValidator',
  VoucherWrite = 'VoucherWrite'
}

export type PromotionConfig = {
  __typename?: 'PromotionConfig';
  enable: Scalars['Boolean'];
  type: PromotionTypeEnum;
  value: Scalars['String'];
};

export type PromotionConfigInput = {
  enable: Scalars['Boolean'];
  type: PromotionTypeEnum;
  value: Scalars['String'];
};

export enum PromotionTypeEnum {
  Image = 'Image',
  Instagram = 'Instagram',
  Text = 'Text'
}

export type QrCodeLink = {
  __typename?: 'QrCodeLink';
  code: Scalars['String'];
  image: Scalars['String'];
  link: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accounts: AccountEdgesResponse;
  agent?: Maybe<Agent>;
  agents: AgentEdgesResponse;
  book?: Maybe<Book>;
  books: BookEdgesResponse;
  campaign?: Maybe<Campaign>;
  campaigns: CampaignEdgesResponse;
  dashboard: DashboardResponse;
  exam?: Maybe<Exam>;
  exams: ExamEdgesResponse;
  infoApi?: Maybe<InfoApi>;
  myUser: User;
  outsideBook?: Maybe<OutsideBook>;
  outsideBooks: OutsideBookEdgesResponse;
  permissions: Array<PermissionEnum>;
  roles: Array<Role>;
  user?: Maybe<User>;
  users: UserEdgesResponse;
  voucher?: Maybe<Voucher>;
  vouchers: VoucherEdgesResponse;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountsArgs = {
  params?: InputMaybe<AccountSearchInput>;
};


export type QueryAgentArgs = {
  id: Scalars['ID'];
};


export type QueryAgentsArgs = {
  params?: InputMaybe<AgentSearchInput>;
};


export type QueryBookArgs = {
  sequential: Scalars['Int'];
};


export type QueryBooksArgs = {
  params?: InputMaybe<BookSearchInput>;
};


export type QueryCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryCampaignsArgs = {
  params?: InputMaybe<CampaignSearchInput>;
};


export type QueryDashboardArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  end?: InputMaybe<Scalars['Date']>;
  start?: InputMaybe<Scalars['Date']>;
};


export type QueryExamArgs = {
  id: Scalars['ID'];
};


export type QueryExamsArgs = {
  params?: InputMaybe<ExamSearchInput>;
};


export type QueryOutsideBookArgs = {
  id: Scalars['ID'];
};


export type QueryOutsideBooksArgs = {
  params?: InputMaybe<OutsideBookSearchInput>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  params?: InputMaybe<UserSearchInput>;
};


export type QueryVoucherArgs = {
  id: Scalars['ID'];
};


export type QueryVouchersArgs = {
  params?: InputMaybe<VoucherSearchInput>;
};

export type ResultExam = {
  __typename?: 'ResultExam';
  astigmatismLeft: ResultTestEnum;
  astigmatismRight: ResultTestEnum;
  contrastLeft: ResultTestEnum;
  contrastRight: ResultTestEnum;
  daltonism: ResultTestEnum;
  field: ResultTestEnum;
  overall: ResultTestEnum;
  snellenLeft: ResultTestEnum;
  snellenRight: ResultTestEnum;
  successAstigmatismLeft: Scalars['Boolean'];
  successAstigmatismRight: Scalars['Boolean'];
  successContrastLeft: Array<Scalars['Boolean']>;
  successContrastRight: Array<Scalars['Boolean']>;
  successDaltonism: Array<Scalars['Boolean']>;
  successField: Scalars['Boolean'];
  successSnellenLeft: Array<Scalars['Boolean']>;
  successSnellenRight: Array<Scalars['Boolean']>;
};

export type ResultExamInput = {
  astigmatismLeft: ResultTestEnum;
  astigmatismRight: ResultTestEnum;
  contrastLeft: ResultTestEnum;
  contrastRight: ResultTestEnum;
  daltonism: ResultTestEnum;
  field: ResultTestEnum;
  overall: ResultTestEnum;
  snellenLeft: ResultTestEnum;
  snellenRight: ResultTestEnum;
  successAstigmatismLeft: Scalars['Boolean'];
  successAstigmatismRight: Scalars['Boolean'];
  successContrastLeft: Array<Scalars['Boolean']>;
  successContrastRight: Array<Scalars['Boolean']>;
  successDaltonism: Array<Scalars['Boolean']>;
  successField: Scalars['Boolean'];
  successSnellenLeft: Array<Scalars['Boolean']>;
  successSnellenRight: Array<Scalars['Boolean']>;
};

export enum ResultTestEnum {
  Bad = 'Bad',
  Good = 'Good',
  Medium = 'Medium'
}

export type Role = {
  __typename?: 'Role';
  permissions: Array<PermissionEnum>;
  role: RoleEnum;
};

export enum RoleEnum {
  Consultant = 'Consultant',
  FranchiseeManger = 'FranchiseeManger',
  UserAccount = 'UserAccount',
  Webmaster = 'Webmaster'
}

export type SerieChart = {
  __typename?: 'SerieChart';
  color?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  label: Scalars['String'];
  percent: Scalars['Float'];
  value: Scalars['Float'];
};

export type SetResultExamResponse = {
  __typename?: 'SetResultExamResponse';
  message: Scalars['String'];
  promotion?: Maybe<AppPromotion>;
  resultImage: Scalars['String'];
  resultLink: Scalars['String'];
  voucher?: Maybe<ExamVoucher>;
  whatsappFromStore: Scalars['String'];
};

export type Sheet = {
  __typename?: 'Sheet';
  /** Apenas o codigo sem o link, mesmo que da folha qrcode */
  code: Scalars['String'];
  /** Apenas o codigo do link de compartilhamento */
  codeShare: Scalars['String'];
  id: Scalars['ID'];
  /** Link de compartilhamento */
  linkShare: Scalars['String'];
  /** Link para o QrCode da folha */
  qrcode: Scalars['String'];
  qtExamFromQrCode: Scalars['Int'];
  qtExamFromShare: Scalars['Int'];
  qtScanned: Scalars['Int'];
  shortId: Scalars['String'];
  status: SheetStatusEnum;
  updatedAt: CustomDate;
};

export enum SheetStatusEnum {
  Available = 'Available',
  Scanned = 'Scanned',
  Used = 'Used'
}

export type SortAccountInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortAgentInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  enable?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortBookInput = {
  checkInAt?: InputMaybe<SortOrderEnum>;
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  dispatchedAt?: InputMaybe<SortOrderEnum>;
  sequential?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
  usedAt?: InputMaybe<SortOrderEnum>;
};

export type SortCampaignInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  enable?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortExamInput = {
  convertedAt?: InputMaybe<SortOrderEnum>;
  createdAt?: InputMaybe<SortOrderEnum>;
  from?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  status12med?: InputMaybe<SortOrderEnum>;
  type?: InputMaybe<SortOrderEnum>;
};

export enum SortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SortOutsideBookInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  enable?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortUserInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  email?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  requiredAction?: InputMaybe<SortOrderEnum>;
  roles?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortVoucherInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  discountType?: InputMaybe<SortOrderEnum>;
  discountValue?: InputMaybe<SortOrderEnum>;
  expiredAt?: InputMaybe<SortOrderEnum>;
  maxCodes?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  qtDrawn?: InputMaybe<SortOrderEnum>;
  qtUsed?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type TransitionBookFailed = {
  __typename?: 'TransitionBookFailed';
  code: TransitionCodeFailEnum;
  message: Scalars['String'];
  sequential: Scalars['Int'];
};

export type TransitionBooksResponse = {
  __typename?: 'TransitionBooksResponse';
  failedQuantity: Scalars['Int'];
  fails: Array<TransitionBookFailed>;
  message: Scalars['String'];
  successQuantity: Scalars['Int'];
};

export enum TransitionCodeFailEnum {
  BookAlreadyDispatched = 'BookAlreadyDispatched',
  BookAlreadyInFranchisee = 'BookAlreadyInFranchisee',
  BookAlreadyInTheStore = 'BookAlreadyInTheStore',
  BookAlreadyUsed = 'BookAlreadyUsed',
  BookDisabled = 'BookDisabled',
  BookNotEnabledYourStore = 'BookNotEnabledYourStore',
  BookNotFound = 'BookNotFound',
  Unknown = 'Unknown'
}

export type User = {
  __typename?: 'User';
  account?: Maybe<Account>;
  accountList?: Maybe<Array<Account>>;
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<PermissionEnum>;
  requiredAction?: Maybe<UserRequiredActionEnum>;
  roles: Array<RoleEnum>;
  sessionAccount?: Maybe<Account>;
  updatedAt: CustomDate;
  username: Scalars['String'];
};

export type UserConditionAndInput = {
  account?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  requiredAction?: InputMaybe<Array<UserRequiredActionEnum>>;
  roles?: InputMaybe<Array<RoleEnum>>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateInput = {
  account?: InputMaybe<Scalars['ID']>;
  accountList?: InputMaybe<Array<Scalars['ID']>>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  requiredAction?: InputMaybe<UserRequiredActionEnum>;
  roles?: InputMaybe<Array<RoleEnum>>;
  username: Scalars['String'];
};

export type UserEdgesResponse = {
  __typename?: 'UserEdgesResponse';
  edges: Array<User>;
  pagination?: Maybe<Pagination>;
};

export type UserEmbed = {
  __typename?: 'UserEmbed';
  id: Scalars['ID'];
  name: Scalars['String'];
  username: Scalars['String'];
};

export type UserPublic = {
  __typename?: 'UserPublic';
  id: Scalars['ID'];
  name: Scalars['String'];
  username: Scalars['String'];
};

export enum UserRequiredActionEnum {
  ChangeEmail = 'ChangeEmail',
  ChangePassword = 'ChangePassword',
  ChangeUsername = 'ChangeUsername',
  ConfirmEmail = 'ConfirmEmail',
  None = 'None',
  UpdateProfile = 'UpdateProfile'
}

export type UserResponse = {
  __typename?: 'UserResponse';
  data: User;
  message: Scalars['String'];
};

export type UserSearchInput = {
  and?: InputMaybe<UserConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortUserInput>;
};

export type UserUpdateInput = {
  account?: InputMaybe<Scalars['ID']>;
  accountList?: InputMaybe<Array<Scalars['ID']>>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  requiredAction?: InputMaybe<UserRequiredActionEnum>;
  roles?: InputMaybe<Array<RoleEnum>>;
  username?: InputMaybe<Scalars['String']>;
};

export type ValidatorExamResponse = {
  __typename?: 'ValidatorExamResponse';
  /** Se esse codigo possui integração com 12med */
  activate12Med: Scalars['Boolean'];
  /** Codigo do pais de origem do QrCode */
  country: CountryEnum;
  /**
   * Link de compartilhamento
   *
   * Obs: caso nao tiver mais exames disponiveis, sera retornado null
   */
  linkShare?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  /** Quantidade de exames disponivel via QrCode */
  quantityAvailableFromQrCode: Scalars['Int'];
  /** Quantidade de exames disponivel via QrCode */
  quantityAvailableFromShare: Scalars['Int'];
  /** Quantidade de exames iniciados via QrCode */
  quantityExamFromQrCode: Scalars['Int'];
  /** Quantidade de exames iniciados via QrCode */
  quantityExamFromShare: Scalars['Int'];
  /** Quantidade de vezes que esta folha foi scaneada seja via QrCode ou Link Compartilhado */
  quantityScanned: Scalars['Int'];
};

export type Voucher = {
  __typename?: 'Voucher';
  /** O tipo de resultado do exame, que é permitido para ganhar o premio */
  allowedResults: Array<ResultTestEnum>;
  codes: Array<VoucherCode>;
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  description?: Maybe<Scalars['String']>;
  discountLabel: Scalars['String'];
  discountType: VoucherDiscountTypeEnum;
  discountValue: Scalars['Float'];
  /** Até quando o premio pode ser sorteado */
  expiredAt: CustomDate;
  id: Scalars['ID'];
  /** Quantidade maxima a ser gerado */
  maxCodes: Scalars['Int'];
  name: Scalars['String'];
  /**
   * Lista de QrCode relacionados ao voucher
   * Quando informado, sera sorteado apenas para estes QrCodes
   */
  outsideBooks: Array<OutsideBook>;
  /** Quantidade de codigos gerados */
  qtDrawn: Scalars['Int'];
  /** Quantidade de codigos usuados */
  qtUsed: Scalars['Int'];
  updatedAt: CustomDate;
};

export type VoucherCode = {
  __typename?: 'VoucherCode';
  code: Scalars['String'];
  confirmedByUser?: Maybe<Scalars['ID']>;
  createdAt: CustomDate;
  exam?: Maybe<Exam>;
  id: Scalars['ID'];
  usedAt?: Maybe<CustomDate>;
};

export type VoucherConditionAndInput = {
  allowedResults?: InputMaybe<Array<ResultTestEnum>>;
  discountType?: InputMaybe<VoucherDiscountTypeEnum>;
  discountValue?: InputMaybe<ConditionFloatInput>;
  expiredAt?: InputMaybe<ConditionDateInput>;
  maxCodes?: InputMaybe<ConditionIntInput>;
  name?: InputMaybe<Scalars['String']>;
  qtDrawn?: InputMaybe<ConditionIntInput>;
  qtUsed?: InputMaybe<ConditionIntInput>;
};

export type VoucherCreateInput = {
  allowedResults: Array<ResultTestEnum>;
  description?: InputMaybe<Scalars['String']>;
  discountType: VoucherDiscountTypeEnum;
  discountValue: Scalars['Float'];
  expiredAt: Scalars['Date'];
  maxCodes: Scalars['Int'];
  name: Scalars['String'];
  outsideBooks?: InputMaybe<Array<Scalars['ID']>>;
};

export type VoucherDashboard = {
  __typename?: 'VoucherDashboard';
  bestVouchers: Array<SerieChart>;
  vouchersByStatus: Array<SerieChart>;
};

export enum VoucherDiscountTypeEnum {
  Amount = 'Amount',
  Percent = 'Percent'
}

export type VoucherEdgesResponse = {
  __typename?: 'VoucherEdgesResponse';
  edges: Array<Voucher>;
  pagination?: Maybe<Pagination>;
};

export type VoucherResponse = {
  __typename?: 'VoucherResponse';
  data: Voucher;
  message: Scalars['String'];
};

export type VoucherSearchInput = {
  and?: InputMaybe<VoucherConditionAndInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortVoucherInput>;
};

export type VoucherSimple = {
  __typename?: 'VoucherSimple';
  description?: Maybe<Scalars['String']>;
  discountLabel: Scalars['String'];
  discountType: VoucherDiscountTypeEnum;
  discountValue: Scalars['Float'];
  /** Até quando o premio pode ser resgatado */
  expiredAt: CustomDate;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type VoucherUpdateInput = {
  allowedResults?: InputMaybe<Array<ResultTestEnum>>;
  description?: InputMaybe<Scalars['String']>;
  discountType?: InputMaybe<VoucherDiscountTypeEnum>;
  discountValue?: InputMaybe<Scalars['Float']>;
  expiredAt?: InputMaybe<Scalars['Date']>;
  maxCodes?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  outsideBooks?: InputMaybe<Array<Scalars['ID']>>;
};
