import store from "@/store";
import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache } from "@apollo/client/core";
import { provideApolloClient } from "@vue/apollo-composable";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import router from "@/router";
import Swal from "sweetalert2";
import { config } from "@/config";
import { version } from '@/../package.json';
import { GraphQLErrors } from "@/codes/types/GraphqlErrors";
import { VUEX_ACTIONS } from "@/codes/util/constants";

export class BaseService {
  constructor() {
    // console.log(config);

    const token = store.getters.authToken;

    const httpLink = new HttpLink({
      // You should use an absolute URL here
      uri: config.urlApi,
    });

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    }

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
      {
        graphQLErrors.forEach((err) => {
          switch (err.extensions.code) {
            // Apollo Server sets code to UNAUTHENTICATED
            // when an AuthenticationError is thrown in a resolver
            case GraphQLErrors.UNAUTHORIZED:
              router.push({ name: "login", query: { returnUrl: router.currentRoute.value.path } });
              break;
            case GraphQLErrors.INVALID_USERNAME_PASSWORD:
              Swal.fire({
                title: "Não foi possível realizar login.",
                text: `${err.message}`
              });
              break;
            case GraphQLErrors.ACCOUNT_REQUIRED:
              store.dispatch(VUEX_ACTIONS.showSelectAccount);
              break;
            case GraphQLErrors.ACL_PERMS:
              router.push({ name: "unauthorized" });
              break;
            case GraphQLErrors.VALIDATION_FAILED: {
              const message = (err.extensions.validation as any[]).map((val) => `${val.field}: ${val.message}`).join('\n');
              Swal.fire({
                title: "Ocorreu um erro!",
                text: message
              });
              break;
            }
            default:
              Swal.fire({
                title: "Ocorreu um erro!",
                text: `${err.message}`
              });
              break;
              //return abc.forward(abc.operation);
          }
          console.log(
            `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
          );
          graphQLErrors = undefined;
        });
      }
      if (networkError) {console.log(`[Network error]: ${networkError}`);}
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
          "Accept-Language": "pt-BR",
          "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    });

    // Create the apollo client
    this.apolloClient = new ApolloClient({
      link: errorLink.concat(authLink).concat(httpLink),
      cache: new InMemoryCache(),
      name: "TEV - Painel",
      version: `v${version.toString()}`,
      defaultOptions: defaultOptions
    });
    provideApolloClient(this.apolloClient);


    if (!token) {
      router.push({ name: "login", query: { returnUrl: router.currentRoute.value.path } });
    }
  }

  public apolloClient: ApolloClient<any>;

}
