import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthLayout from '@/layout/auth-layout.vue'
import AppLayout from '@/layout/app-layout.vue'
import Page404Layout from '@/layout/page-404-layout.vue'
import { ROUTE_NAMES } from '@/codes/util/constants';
import { config } from "@/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: 'home' },
  },
  {
    name: "admin",
    path: "/admin",
    component: AppLayout,
    children: [
      {
        path: "home",
        name: ROUTE_NAMES.home,
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "about",
        name: "about",
        component: () => import("@/views/AboutView.vue"),
      },
      {
        path: "accounts",
        name: ROUTE_NAMES.accounts,
        component: () => import("@/views/account/ListAccounts.vue"),
      },
      {
        path: "myaccount",
        name: ROUTE_NAMES.myAccount,
        component: () => import("@/views/account/MyAccount.vue"),
      },
      {
        path: "accounts/new",
        name: ROUTE_NAMES.newAccount,
        component: () => import("@/views/account/AccountForm.vue"),
      },
      {
        path: "accounts/edit/:id",
        name: ROUTE_NAMES.editAccount,
        component: () => import("@/views/account/AccountForm.vue"),
      },
      {
        path: "users",
        name: ROUTE_NAMES.users,
        component: () => import("@/views/user/ListUsers.vue"),
      },
      {
        path: "users/new",
        name: ROUTE_NAMES.newUser,
        component: () => import("@/views/user/UserForm.vue"),
      },
      {
        path: "users/edit/:id",
        name: ROUTE_NAMES.editUser,
        component: () => import("@/views/user/UserForm.vue"),
      },
      {
        path: "books",
        name: ROUTE_NAMES.books,
        component: () => import("@/views/book/ListBooks.vue"),
      },
      {
        path: "agents",
        name: ROUTE_NAMES.agents,
        component: () => import("@/views/agent/ListAgents.vue"),
      },
      {
        path: "campaigns",
        name: ROUTE_NAMES.campaigns,
        component: () => import("@/views/campaign/ListCampaigns.vue"),
      },
      {
        path: "vouchers",
        name: ROUTE_NAMES.vouchers,
        component: () => import("@/views/voucher/ListVouchers.vue"),
      },
      {
        path: "vouchers/new",
        name: ROUTE_NAMES.newVoucher,
        component: () => import("@/views/voucher/VoucherForm.vue"),
      },
      {
        path: "vouchers/edit/:id",
        name: ROUTE_NAMES.editVoucher,
        component: () => import("@/views/voucher/VoucherForm.vue"),
      },
      {
        path: "outsideBooks",
        name: ROUTE_NAMES.outsideBooks,
        component: () => import("@/views/outsideBook/ListOutsideBooks.vue"),
      },
      {
        path: "outsideBooks/new",
        name: ROUTE_NAMES.newOutsideBook,
        component: () => import("@/views/outsideBook/OutsideBookForm.vue"),
      },
      {
        path: "outsideBooks/edit/:id",
        name: ROUTE_NAMES.editOutsideBook,
        component: () => import("@/views/outsideBook/OutsideBookForm.vue"),
      },
      {
        path: "exams",
        name: ROUTE_NAMES.exams,
        component: () => import("@/views/exam/ListExam.vue"),
      },
      {
        path: 'scan',
        name: ROUTE_NAMES.scan,
        component: () => {
          window.location.href = config.urlScanner;
        },
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('@/views/auth/AuthLogin.vue'),
      },
    ]
  },
  {
    path: '/error',
    component: Page404Layout,
    children: [
      {
        name: 'not-found-simple',
        path: '404',
        component: () => import('@/views/error/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'unauthorized',
        path: 'unauthorized',
        component: () => import('@/views/error/VaUnauthorizedSimple.vue'),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/error/404'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
});

// //const st = store;
// router.beforeEach((to, from, next) => {
//   if (to && to.name) {
//     const permissions = PermissionsByRoute[to.name.toString()];
//     if (!permissions || (permissions && store.getters.hasPermissions(permissions))) {
//       return next();
//     } else {
//       debugger;
//       router.replace({ name: 'unauthorized' });
//       //return next();
//     }
//   }
//   else {
//     return next();
//   }
// });

export default router
