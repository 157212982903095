import { VuesticPlugin } from "vuestic-ui";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuesticGlobalConfig from "./services/vuestic-ui/global-config";
import { i18n } from "@/i18n/i18n";

import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/mdo-visao.css";

import BaseList from "@/components/mdo/BaseList.vue";
import BaseForm from "@/components/mdo/BaseForm.vue";

import { Form, Field, defineRule, configure, ErrorMessage } from 'vee-validate';
// eslint-disable-next-line
import { required, email, min, max, confirmed, length, min_value } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('confirmed', confirmed);
defineRule('length', length);
// eslint-disable-next-line
defineRule('min_value', min_value);
import { localize, setLocale } from '@vee-validate/i18n';
/* eslint-disable @typescript-eslint/camelcase */
import pt_BR from '@vee-validate/i18n/dist/locale/pt_BR.json';
import br from "./i18n/br.json";

configure({
  generateMessage: localize({
    pt_BR: {
      messages: pt_BR.messages,
      names: {
        'configs.promotion.type': "Tipo",
        'configs.promotion.value': "Valor",
        ...br.fields,
        ...br.register,
        ...br.users,
        ...br.account,
        ...br.address
      }
    }
  }),
});

setLocale('pt_BR');
/* eslint-enable @typescript-eslint/camelcase */

import "cleave.js/dist/addons/cleave-phone.br.js";

const config = vuesticGlobalConfig;
config.colors.primary = "#bf4427";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(VuesticPlugin, config);
app.component("BaseList", BaseList);
app.component("BaseForm", BaseForm);
app.component("VForm", Form);
app.component("VField", Field);
app.component("ErrorMessage", ErrorMessage);
app.mount("#app");
