import { User } from "@/codes/gen/types";
import { VUEX_ACTIONS } from "@/codes/util/constants"

export default {

  [VUEX_ACTIONS.setAuthToken]: function({ commit }: any, token: string) {
    commit(VUEX_ACTIONS.setAuthToken, token);
  },
  [VUEX_ACTIONS.updateAuthToken]: function({ commit }: any, token: string) {
    commit(VUEX_ACTIONS.setAuthToken, token);
  },
  [VUEX_ACTIONS.logout]: function({ commit }: any) {
    commit(VUEX_ACTIONS.logout);
  },
  [VUEX_ACTIONS.setUserInfo]: function({ commit }: any, userInfo: User) {
    commit(VUEX_ACTIONS.setUserInfo, userInfo);
  },
  [VUEX_ACTIONS.showSelectAccount]: function() {
    // Apenas para chamar o subscribe dessa actions
  },
  [VUEX_ACTIONS.hideSelectAccount]: function() {
    // Apenas para chamar o subscribe dessa actions
  },
}