import gql from "graphql-tag";
import {
  User,
  UserCreateInput,
  UserEdgesResponse,
  UserResponse,
  UserSearchInput,
  UserUpdateInput
} from "@/codes/gen/types";
import { BaseService } from "./BaseService";
import { ResponseGql } from "@/codes/types/MDOTypes";

export class UserService extends BaseService {

  constructor() {
    super();
  }

  get(id: string): ResponseGql<User> {
    const query = gql`query user($id: ID!) {
      user(id: $id) {
        id
        name
        username
        email
        roles
        permissions
        requiredAction
        deletedAt{
          transform(format: "DD/MM/yyyy HH:mm")
        }
        accountList {
          id
        }
        account {
          id
          name
        }
       
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id }}).then((result) => {
      return result.data.user;
    })
  }

  getAll(filter: UserSearchInput): ResponseGql<UserEdgesResponse> {
    const query = gql`query users($paginationUser: UserSearchInput) {
      users(params: $paginationUser) {
        edges {
          id
          name
          username
          email
          roles
          deletedAt{
            transform(format: "DD/MM/yyyy HH:mm")
          }
        }
        pagination {
          total
          page
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { paginationUser: filter } }).then((response) => {
      return response.data.users;
    });
  }

  getUserInfo(): ResponseGql<User> {
    const query = gql`query Profile {
      myUser {
        id
        username
        roles
        permissions
        requiredAction
        account {
          id
          name
        }
        sessionAccount {
          id
          name
        }
      }
    }`;

    return this.apolloClient.query({ query: query }).then((response) => {
      return response.data.myUser;
    });
  }

  save(user: UserCreateInput): ResponseGql<UserResponse> {
    const createUser = gql`mutation createUser($input: UserCreateInput!) {
      createUser(input: $input) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: createUser, variables: { input: user } }).then((response) => {
      return response.data.createUser;
    });
  }

  update(id: string, user: UserUpdateInput): ResponseGql<UserResponse> {
    const updateUser = gql`mutation updateUser($id: ID!, $input: UserUpdateInput!) {
      updateUser(id: $id, input: $input) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: updateUser, variables: { id: id, input: user } }).then((response) => {
      return response.data.updateUser;
    });
  }

  remove(id: string): ResponseGql<UserResponse> {
    const deleteUser = gql`mutation removeUser($id: ID!) {
      removeUser(id: $id) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: deleteUser, variables: { id: id } }).then((response) => response.data.removeUser);
  }

  restore(id: string): ResponseGql<UserResponse> {
    const query = gql`
      mutation restoreUser($id: ID!) {
        restoreUser(id: $id) {
          message
          data {
            id
          }
        }
      }`;

    return this.apolloClient.mutate({ mutation: query, variables: { id: id }}).then((result) => {
      return result.data.restoreUser
    });
  }
}
