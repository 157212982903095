import gql from "graphql-tag";
import { LoginInput, LoginResponse } from "@/codes/gen/types";
import { BaseService } from "./BaseService";
import { ResponseGql } from "@/codes/types/MDOTypes";

export class AuthService extends BaseService {

  constructor() {
    super();
  }

  login(loginInput: LoginInput): ResponseGql<LoginResponse> {
    const queryInfo = gql`mutation login ($loginInput: LoginInput!){
      login(input: $loginInput) {
        expiresIn
        accessToken
        Authorization
      }
    }`

    return this.apolloClient.mutate({ mutation: queryInfo, variables: { loginInput: loginInput } }).then((response) => response.data.login);
  }

  changeAccount(idAccount: string): ResponseGql<LoginResponse> {
    const loginWithAccount = gql`mutation abc($accountId: ID!) {
      loginWithAccount(accountId: $accountId) {
        accessToken
      }
    }`;

    return this.apolloClient.mutate({ mutation: loginWithAccount, variables: { accountId: idAccount }}).then((response) => response.data.loginWithAccount);
  }

}

// apollo link error - pesquisar

// desabilitar cache do apollo